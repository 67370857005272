import { group, r } from '@/utils/router-helpers'
export const base = [
  group('/login', 'views/login'),
  group('/', 'layout', [
    r('home/index', '首页'),
    r('home/login', '登录'),
    r('home/my-meeting', '我的会议'),
    r('home/mail-list', '通讯录'),
    r('home/cloud-disk', '云盘'),
    r('home/videotape', '录像'),
    r('home/swich', '切换企业'),
    r('home/join', '加入会议'),
    r('home/join-meeting', '加入会议'),
  ], '', '/home/index')
]



