import Vue from 'vue'
import 'view-design/dist/styles/iview.css'
import AppImg from '@/components/common/Image'
import {
  Avatar,
  Button,
  Icon,
  Carousel,
  Upload,
  CarouselItem,
  Circle,
  Input,
  Card,
  Select,
  Option,
  Message,
  Form,
  FormItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Modal,
  Page,
  Spin,
  Checkbox,
  Tooltip,
  DatePicker,
  RadioGroup,
  Radio,
  Tree,
  TimePicker
} from 'view-design'


Vue.component('AppImg', AppImg);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('i-form', Form);
Vue.component('i-page', Page);
Vue.component('i-form-item', FormItem);
Vue.component('IButton', Button);
Vue.component('Icon', Icon);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);
Vue.component('i-circle', Circle);
Vue.component('i-input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Card', Card);
Vue.component('i-select', Select);
Vue.component('i-option', Option);
Vue.component('i-upload', Upload);
Vue.component('i-modal', Modal);
Vue.component('i-spin', Spin);
Vue.component('i-checkbox', Checkbox);
Vue.component('i-radio-group', RadioGroup);
Vue.component('i-radio', Radio);
Vue.component('i-tree', Tree);
Vue.component('i-avatar', Avatar);
Vue.component('Tooltip', Tooltip);
Vue.component('TimePicker', TimePicker);

Vue.prototype.$message = Message
Vue.prototype.$Modal = Modal


