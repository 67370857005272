import router from '../router'
import store from '../store'
import { LoadingBar } from 'view-design'
import getPageTitle from '@/utils/get-page-title'

LoadingBar.config({
  color: '#48C1C4',
  failedColor: '#f0ad4e',
  height: 2
})

const whiteList = ['/home/login', '/home/index', '/home/join','/home/join-meeting'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  LoadingBar.start()
  window.document.title = getPageTitle(to.meta.title)
  // set page title
  window.scrollTo(0,0)
  // window.scrollTo(0)

  // determine whether the user has logged in
  const hasToken = store.state.vuex_token
  if (hasToken) {
    if (to.path === '/home/login') {
      // if is logged in, redirect to the home page
      next({ path: '/home/index' })
      
      LoadingBar.finish()
    } else {
      const hasGetUserInfo = store.state.vuex_token
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          // get user info
          await store.dispatch('getUserInfo')
          next()
        } catch (error) {
          // remove token and go to login page to re-login
          store.commit('$uStore', {
            name: 'vuex_token',
            value: ''
          })
          store.commit('$uStore', {
            name: 'vuex_user_info',
            value: {}
          })
          next(`/home/login?redirect=${to.path}`)
          LoadingBar.finish()
        }
      }
    }
  } else {
    /* has no token*/
    console.log(to.path)
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/home/login?redirect=${to.path}`)
      LoadingBar.finish()
    }
  }
})

router.afterEach(() => {
  // 
  // finish progress bar
  LoadingBar.finish()
})
