/**
 * 返回分组路由的设置对象
 * @param path {String|null} 分组的路径。缺省：null
 * @param title {String} 分组的标题
 * @param children {[Object]} 页面路由设置对象的数组
 * @returns {Object} 分组路由的设置对象
 */
export function group (path = '', component, children, title, redirect) {
  return {
    path,
    component: () => import(`@/${component}`),
    children,
    meta: { title },
    redirect
  }
}


/**
 * 返回页面路由的设置对象
 * @param comp {Object} 页面的对象
 * @param title {String} 页面的标题
 * @param conf {String|{path: String, props: Object}} 页面路径及附加设置。如果为字符串，则为页面路径；否则为包含path和props的对象，其中：path为页面路径，props为页面对象的属性参数
 * @returns {Object} 页面路由的设置对象
 */
export function r (path = '', title, url) {
  return {
    path: url || path,
    name: path,
    component: () => import(`@/views/${path}`),
    meta: { title },
  }
}
