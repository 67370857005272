// 引入依赖  
import Vue from 'vue'  
import Vuex from 'vuex'  
import store from '@/utils/function/store'
Vue.use(Vuex)  

let lifeData = store('lifeData') || {};

// 需要永久存储，且下次APP启动需要取出的，在state中的变量名
let saveStateKeys = [
  'vuex_token',
  'vuex_user_info',
];

// 保存变量到本地存储中
const saveLifeData = function(key, value){
	// 判断变量名是否在需要存储的数组中
	if(saveStateKeys.indexOf(key) != -1) {
		// 获取本地存储的lifeData对象，将变量添加到对象中
		let tmp = store('lifeData');
		// 第一次打开APP，不存在lifeData变量，故放一个{}空对象
		tmp = tmp ? tmp : {};
		tmp[key] = value;
		// 执行这一步后，所有需要存储的变量，都挂载在本地的lifeData对象中
		store('lifeData', tmp);
	}
}


const state = {  
  vuex_user_info: lifeData.vuex_user_info || { user_info: {} },
  vuex_token: lifeData.vuex_token || '',
}


const mutations = {
  SET_STATE: (state, { name, val }) => {
    state[name] = val
  },
  $uStore(state, payload) {
    // 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
    let nameArr = payload.name.split('.');
    let saveKey = '';
    let len = nameArr.length;
    if(nameArr.length >= 2) {
      let obj = state[nameArr[0]];
      for(let i = 1; i < len - 1; i ++) {
        obj = obj[nameArr[i]];
      }
      obj[nameArr[len - 1]] = payload.value;
      saveKey = nameArr[0];
    } else {
      // 单层级变量，在state就是一个普通变量的情况
      state[payload.name] = payload.value;
      saveKey = payload.name;
    }
    // 保存变量到本地，见顶部函数定义
    saveLifeData(saveKey, state[saveKey])
  }
}

const actions = {

  getUserInfo({ commit }) {
    commit('$uStore', {
      name: 'vuex_user_info',
      value: store('userInfo')
    })
  },

  getJuperList({ commit }) {
    getJupter().then(res => {
      commit('$uStore', {
				name: 'vuex_jupter',
        value: res.data
			})
    })
  },


  getOrganizationInfo({ commit, state }) {
    getOrganizationInfo().then(res => {
      store('userInfo', {
        ...(store('userInfo') || {}),
        ...res.data
      })
      commit('$uStore', {
				name: 'vuex_user_info',
        value: {
          ...state.vuex_user_info,
          ...res.data
        } 
			})
    })
  }
  
}

export default new Vuex.Store({  
  state,  
  actions,
  mutations
})