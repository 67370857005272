import Vue from 'vue'
import Router from 'vue-router'
import { base } from './route'
Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: base
})

