import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem'
import mixin from '@/store/mixin'
import './plugin/iview'
import '@/utils/permission'
import '@/assets/css/base.scss'

const openMeeting = url => {

  let hostArr = url.split('?')

  let mr = hostArr[0].split('/')
  let m = mr[mr.length - 1]

  let mstr = `${m}&${hostArr[1]}`

  window.open(`${window.location.pathname}#/home/join-meeting?m=${mstr}&url=${encodeURIComponent(url)}`)
}
Vue.prototype.openMeeting = openMeeting


Vue.config.productionTip = false
Vue.mixin(mixin)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
