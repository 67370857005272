var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-image",style:([_vm.wrapStyle, _vm.backgroundStyle]),on:{"click":_vm.onClick}},[(!_vm.isError)?_c('img',{staticClass:"u-image__image",style:({
				'object-fit': _vm.mode,
				borderRadius: _vm.shape == 'circle' ? '50%' : _vm.addUnit(_vm.borderRadius)
			}),attrs:{"src":_vm.src,"mode":_vm.mode,"lazy-load":_vm.lazyLoad},on:{"error":_vm.onErrorHandler,"load":_vm.onLoadHandler}}):_vm._e(),(_vm.showLoading && _vm.loading)?_c('div',{staticClass:"u-image__loading",style:({
				borderRadius: _vm.shape == 'circle' ? '50%' : _vm.addUnit(_vm.borderRadius),
				backgroundColor: this.bgColor
			})},[(_vm.$slots.loading)?_vm._t("loading"):_c('Icon',{attrs:{"type":_vm.loadingIcon,"size":(_vm.width > _vm.height ? _vm.width : _vm.height) / 2}})],2):_vm._e(),(_vm.showError && _vm.isError && !_vm.loading)?_c('div',{staticClass:"u-image__error",style:({
				borderRadius: _vm.shape == 'circle' ? '50%' : _vm.addUnit(_vm.borderRadius)
			})},[(_vm.$slots.error)?_vm._t("error"):_c('Icon',{attrs:{"type":_vm.errorIcon,"size":(_vm.width > _vm.height ? _vm.width : _vm.height) / 2}})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }